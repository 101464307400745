import {PrismaClient} from "@prisma/client"

export const prisma = new PrismaClient({
  // log: ['query', 'info', 'warn', 'error'],
})

export enum Industry {
  AI = "AI",
  Analytics = "Analytics",
  Business_Services = "Business Services",
  Collaboration_Productivity = "Collaboration & Productivity",
  Commerce = "Commerce",
  Content_Management = "Content Management",
  Customer_Service = "Customer Service",
  Design = "Design",
  Development = "Development",
  Digital_Advertising = "Digital Advertising",
  ERP = "ERP",
  G2_Industry = "G2 Industry",
  HR = "HR",
  Hosting = "Hosting",
  IT_Infrastructure = "IT Infrastructure",
  IT_Management = "IT Management",
  Marketing = "Marketing",
  Marketing_Services = "Marketing Services",
  Office = "Office",
  Other = "Other",
  Other_Services = "Other Services",
  Professional_Services = "Professional Services",
  Sales = "Sales",
  Sales_Tools = "Sales Tools",
  Security = "Security",
  Supply_Chain_Logistics = "Supply Chain & Logistics",
  Vertical_Industry = "Vertical Industry",
}

export enum Category {
  AP_Automation = "AP Automation",
  Accounting = "Accounting",
  Accounting_Practice_Management = "Accounting Practice Management",
  Accounts_Receivable_Automation = "Accounts Receivable Automation",
  Analytics_Platforms = "Analytics Platforms",
  Applicant_Tracking_Systems = "Applicant Tracking Systems",
  Application_Development_Software = "Application Development Software",
  Bot_Platforms = "Bot Platforms",
  Business_Filing_and_Licensing = "Business Filing and Licensing",
  Business_Process_Management = "Business Process Management",
  CMS_Tools = "CMS Tools",
  CRM = "CRM",
  CRM_Software = "CRM Software",
  Chatbots = "Chatbots",
  Client_Portal = "Client Portal",
  Communication_Platform_as_a_Service = "Comm Platform as a Service",
  Competitive_Intelligence = "Competitive Intelligence",
  Component_Libraries = "Component Libraries",
  Content_Creation = "Content Creation",
  Content_Experience_Platforms = "Content Experience",
  Core_HR = "Core HR",
  Cryptocurrency_Wallets = "Cryptocurrency Wallets",
  Cybersecurity = "Cybersecurity",
  Customer_Communications_Management = "Customer Comm Management",
  Drop_Shipping = "Drop Shipping",
  ECommerce_Analytics = "E-Commerce Analytics",
  ECommerce_Data_Integration = "E-Commerce Data Integration",
  ECommerce_Development = "E-Commerce Development",
  ECommerce_Fraud_Protection = "E-Commerce Fraud Protection",
  ECommerce_Platforms = "E-Commerce Platforms",
  ESignature = "E-Signature",
  Email_Deliverability = "Email Deliverability",
  Email_Management = "Email Management",
  Email_Marketing = "Email Marketing",
  Email_Verification = "Email Verification",
  Employee_Recognition = "Employee Recognition",
  Event_Registration_Ticketing = "Event Reg. & Ticketing",
  Freelance_Platforms = "Freelance Platforms",
  Fundraising = "Fundraising",
  Graphic_Design_Services = "Graphic Design Services",
  HR_Analytics = "HR Analytics",
  Help_Desk = "Help Desk",
  Image_Optimization = "Image Optimization",
  Inbound_Call_Tracking = "Inbound Call Tracking",
  International_PEO = "International PEO",
  Job_Description_Management = "Job Desc. Management",
  Landing_Page_Builders = "Landing Page Builders",
  Lead_Capture = "Lead Capture",
  Lead_Generation_Services = "Lead Gen. Services",
  Lead_Intelligence = "Lead Intelligence",
  Live_Chat = "Live Chat",
  Live_Stream_Software = "Live Stream Software",
  Low_Code_Development_Platforms = "Low-Code Platforms",
  Managed_Hosting = "Managed Hosting",
  Marketing_Automation = "Marketing Automation",
  Medical_Practice_Management = "Medical Practice Management",
  Mental_Health = "Mental Health",
  Multi_Country_Payroll = "Multi-Country Payroll",
  Network_Monitoring = "Network Monitoring",
  No_Code_Development_Platforms = "No-Code Platforms",
  Online_Appointment_Scheduling = "Online Appt. Scheduling",
  Online_Course_Providers = "Online Course Providers",
  Online_Form_Builder = "Online Form Builder",
  Online_Learning_Platform = "Learning Platform",
  Online_Marketplace_Optimization_Tools = "Online Marketplace Optimization",
  Other = "Other",
  Other_B2B_Services = "Other B2B Services",
  Other_Business_Finance = "Other Business Finance",
  Other_Design = "Other Design",
  Other_Fitness = "Other Fitness",
  Other_Lead_Generation = "Other Lead Generation",
  Other_Legal = "Other Legal",
  Other_Marketing = "Other Marketing",
  Other_Marketing_Services = "Other Marketing Services",
  Other_Office = "Other Office",
  Payment_Processing = "Payment Processing",
  Payroll = "Payroll",
  Penetration_Testing = "Penetration Testing",
  Personal_Training = "Personal Training",
  Pop_Up_Builder = "Pop-Up Builder",
  Print_Fulfillment = "Print Fulfillment",
  Project_Management = "Project Management",
  Proposal = "Proposal",
  Restaurant_POS = "Restaurant POS",
  Retail_Media_Advertising_Platforms = "Retail Media Ad Platforms",
  Rewards_and_Incentives = "Rewards and Incentives",
  Risk_Based_Vulnerability_Management="Risk-Based Vulnerability Management",
  SMS_Marketing = "SMS Marketing",
  Sales_Coaching = "Sales Coaching",
  Sales_Engagement = "Sales Engagement",
  Sales_Intelligence = "Sales Intelligence",
  Screen_Sharing = "Screen Sharing",
  Shipping = "Shipping",
  Social_Media_Management = "Social Media Management",
  Social_Media_Marketing_Companies = "Social Media Marketing",
  Social_Media_Monitoring = "Social Media Monitoring",
  Space_Management = "Space Management",
  Staffing = "Staffing",
  Standard_Operating_Procedures = "Standard Operating Procedures",
  Subscription_Management = "Subscription Management",
  Survey = "Survey",
  Task_Management = "Task Management",
  Time_Tracking = "Time Tracking",
  Training_Management_Systems = "Training Management Systems",
  Transactional_Email = "Transactional Email",
  User_Generated_Content = "User-Generated Content",
  Vacation_Rental = "Vacation Rental",
  Video_Editing = "Video Editing",
  Video_Email = "Video Email",
  Virtual_Workspaces = "Virtual Workspaces",
  Visitor_Management = "Visitor Management",
  VoIP = "VoIP",
  Website_Design = "Website Design",
  Workflow_Management = "Workflow Management",
}
