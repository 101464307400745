// ** React Imports
import { ReactNode } from "react"

// ** Next Imports
import Head from "next/head"
import { Router } from "next/router"
import type { NextPage } from "next"
import type { AppProps } from "next/app"

// ** Store Imports
import { store } from "@wildeye/frontend/src/store"
import { Provider } from "react-redux"

// ** Loader Import
import NProgress from "nprogress"

// ** Emotion Imports
import { CacheProvider } from "@emotion/react"
import type { EmotionCache } from "@emotion/cache"

// ** Config Imports
import "@wildeye/frontend/src/configs/i18n"
import { defaultACLObj } from "@wildeye/frontend/src/configs/acl"
import themeConfig from "@wildeye/frontend/src/configs/themeConfig"

// ** Fake-DB Import
import "@wildeye/frontend/src/@fake-db"

// ** Third Party Import
import { Toaster } from "react-hot-toast"
import {LicenseInfo} from '@mui/x-license'

// ** Component Imports
import UserLayout from "@wildeye/frontend/src/layouts/UserLayout"
import ThemeComponent from "@wildeye/frontend/src/@core/theme/ThemeComponent"

// ** Spinner Import
import Spinner from "@wildeye/frontend/src/layouts/components/spinner"

// ** Contexts
import { SettingsConsumer, SettingsProvider } from "@wildeye/frontend/src/@core/context/settingsContext"
import AclGuard from "@wildeye/frontend/src/layouts/components/auth/UserAclGuard"
import AuthGuard from "@wildeye/frontend/src/layouts/components/auth/UserAuthGuard"
import GuestGuard from "@wildeye/frontend/src/layouts/components/auth/UserGuestGuard"
import { SessionProvider } from "next-auth/react"

// ** Styled Components
import ReactHotToast from "@wildeye/frontend/src/@core/styles/libs/react-hot-toast"

// ** Scrape_utils Imports
import { createEmotionCache } from "@wildeye/frontend/src/@core/utils/create-emotion-cache"

// ** Prismjs Styles
import "prismjs"
import "prismjs/themes/prism-tomorrow.css"
import "prismjs/components/prism-jsx"

// ** React Perfect Scrollbar Style
import "react-perfect-scrollbar/dist/css/styles.css"

import "@wildeye/frontend/src/iconify-bundle/icons-bundle-react"

// ** Global css styles
import "@wildeye/frontend/styles/globals.css"

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  Component: NextPage
  emotionCache: EmotionCache
}

type GuardProps = {
  authGuard: boolean
  guestGuard: boolean
  children: ReactNode
}

const clientSideEmotionCache = createEmotionCache()

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on("routeChangeStart", () => {
    NProgress.start()
  })
  Router.events.on("routeChangeError", () => {
    NProgress.done()
  })
  Router.events.on("routeChangeComplete", () => {
    NProgress.done()
  })
}

const Guard = ({ children, authGuard, guestGuard }: GuardProps) => {
  if (guestGuard) {
    return <GuestGuard fallback={<Spinner />}>{children}</GuestGuard>
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>
  } else {
    return <AuthGuard fallback={<Spinner />}>{children}</AuthGuard>
  }
}

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY as string)

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  // Variables
  const contentHeightFixed = Component.contentHeightFixed ?? false
  const getLayout =
    Component.getLayout ?? (page => <UserLayout contentHeightFixed={contentHeightFixed}>{page}</UserLayout>)

  const setConfig = Component.setConfig ?? undefined

  const authGuard = Component.authGuard ?? true

  const guestGuard = Component.guestGuard ?? false

  const aclAbilities = Component.acl ?? defaultACLObj

  return (
    <Provider store={store}>
      <CacheProvider value={emotionCache}>
        <Head>
          <title>{`${themeConfig.templateName}`}</title>
          <meta
            name='description'
            content={`${themeConfig.templateName}`}
          />
          <meta name='keywords' content='Affiliate offers, warm introductions, prosperity, community, business, professionals, passive income'/>
          <meta name='viewport' content='initial-scale=1, width=device-width'/>
          <meta property="og:title" content="Warmlink"/>
          <meta property="og:description" content="Warmlink is a global affiliate marketplace and community for for professionals looking for top-rated business products and services, community and significant, free passive income."/>
          <meta property="og:url" content="https://warmlink.io"/>
          <meta property="og:image" content="https://storage.googleapis.com/warmlinkprod/warmlink-logo-og-1200x627.jpg"/>
          <meta property="og:type" content="website"/>
          <meta property="og:site_name" content="Warmlink"/>
          <meta property="og:locale" content="en_US"/>
          <link rel="icon" href="https://warmlink.io/images/favicon.png" type="image/x-icon"/>
      </Head>

        <SessionProvider session={pageProps.session}>
          <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
            <SettingsConsumer>
              {({ settings }) => {
                return (
                  <ThemeComponent settings={settings}>
                    <Guard authGuard={authGuard} guestGuard={guestGuard}>
                      <AclGuard aclAbilities={aclAbilities} guestGuard={guestGuard} authGuard={authGuard}>
                        {getLayout(<Component {...pageProps} />)}
                      </AclGuard>
                    </Guard>
                    <ReactHotToast>
                      <Toaster position={settings.toastPosition} toastOptions={{ className: "react-hot-toast" }} />
                    </ReactHotToast>
                  </ThemeComponent>
                )
              }}
            </SettingsConsumer>
          </SettingsProvider>
        </SessionProvider>
      </CacheProvider>
    </Provider>
  )
}

export default App
